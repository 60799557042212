import { useCallback, useEffect, useMemo, useRef } from "react";

const setsEqual = (a: Set<string>, b: Set<string>) => a.size === b.size && Array.from(a).every(b.has.bind(b));

export type PrimaryKey =
  | "A"
  | "B"
  | "C"
  | "D"
  | "E"
  | "F"
  | "G"
  | "H"
  | "I"
  | "J"
  | "K"
  | "L"
  | "M"
  | "N"
  | "O"
  | "P"
  | "Q"
  | "R"
  | "S"
  | "T"
  | "U"
  | "V"
  | "W"
  | "X"
  | "Y"
  | "Z"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "0"
  | "`"
  | "-"
  | "="
  | "["
  | "]"
  | "\\"
  | "/"
  | ","
  | "."
  | "Enter"
  | "Tab"
  | "Delete"
  | "Backspace"
  | "ArrowUp"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowDown"
  | "Space"
  | "Escape";

export type ModifierKey = "Meta" | "Shift" | "Alt" | "Control";
export type KeyboardKey = PrimaryKey | ModifierKey;
export type KeyboardShortcut = KeyboardKey | KeyboardKey[];
export type UseShortCutHandler = (event: KeyboardEvent, keysPressed: Set<KeyboardKey>, isInTextInput: boolean) => void;

export type UseShortcutOptions = {
  enabled?: boolean;
  target?: HTMLElement | Document;
};

export function useShortcut(
  keys: KeyboardShortcut | undefined,
  handler: UseShortCutHandler,
  options: UseShortcutOptions = {}
) {
  const { enabled = true, target = document } = options;
  const handleShortcutTrigger = useCallback<UseShortCutHandler>(
    (...args) => {
      if (enabled) handler(...args);
    },
    [enabled, handler]
  );

  const timers = useRef<Partial<{ [key in KeyboardKey]: NodeJS.Timeout }>>({});
  const targetKeys = useMemo(() => {
    let keysArr: KeyboardKey[];

    if (!keys) keysArr = [];
    else keysArr = Array.isArray(keys) ? keys : [keys];

    return new Set<KeyboardKey>(keysArr.map((key) => key));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(keys)]);
  const pressedKeys = useRef(new Set<KeyboardKey>());

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key as KeyboardKey;
      if (!key) return;
      if (targetKeys.has(key)) pressedKeys.current.add(key);

      const timer = timers.current[key];
      if (timer) clearTimeout(timer);

      if (setsEqual(pressedKeys.current, targetKeys)) {
        // in case only some keys are released
        Object.keys(timers.current).forEach((k) => {
          clearTimeout(timers.current[k]);
        });

        handleShortcutTrigger(
          event,
          pressedKeys.current,
          document.activeElement?.tagName === "INPUT" || document.activeElement?.tagName === "TEXTAREA"
        );
      } else {
        timers.current[key] = setTimeout(() => {
          pressedKeys.current = new Set();
        }, 2500);
      }
    },
    [timers, handleShortcutTrigger, pressedKeys, targetKeys]
  );

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.type === "commandbar-shortcut-executed") return (pressedKeys.current = new Set<KeyboardKey>());
      const key = event.key as KeyboardKey;
      if (!key) return;

      const timer = timers.current[key];
      if (timer) clearTimeout(timer);

      // Key-ups don't fire while meta is held down.  It's a
      // little strange but because of the general use-case
      // of the meta key we can just clear all down keys when
      // it comes up.
      if (key === "Meta") pressedKeys.current.clear();
      else pressedKeys.current.delete(key);
    },
    [pressedKeys, timers]
  );

  useEffect(() => {
    if (!target) return;
    target.addEventListener("keydown", onKeyDown);
    target.addEventListener("keyup", onKeyUp);
    target.addEventListener("commandbar-shortcut-executed", onKeyUp);

    return () => {
      target.removeEventListener("keydown", onKeyDown);
      target.removeEventListener("keyup", onKeyUp);
      target.removeEventListener("commandbar-shortcut-executed", onKeyUp);
    };
  }, [onKeyDown, onKeyUp, target]);
}

export default useShortcut;
