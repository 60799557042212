import { isValidElement, ReactElement, ReactNode } from "react";

export function retry(fn: () => Promise<{ default: React.ComponentType<any> }>, retries = 5, interval = 1000) {
  return new Promise<{ default: React.ComponentType<any> }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((err: any) => {
        setTimeout(() => {
          if (retries <= 1) return reject(err);
          retry(fn, interval, retries - 1).then(resolve, reject);
        }, interval);
      });
  });
}

export default {
  retry,
};

export function isTextInput(el: HTMLElement) {
  if ("TEXTAREA" === el.nodeName) return true;
  if (
    "INPUT" === el.nodeName &&
    !!el.hasAttribute &&
    el.hasAttribute("type") &&
    ["text", "email", "month", "number", "password", "search", "tel", "time", "url", "week"].includes(
      el.getAttribute("type") || ""
    )
  )
    return true;
  return false;
}

export function isAnchorElMissing(anchorEl) {
  const resolvedAnchorEl = typeof anchorEl === "function" ? anchorEl() : anchorEl;

  if (resolvedAnchorEl && resolvedAnchorEl.nodeType === 1) {
    const box = resolvedAnchorEl.getBoundingClientRect();

    if (process.env.NODE_ENV !== "test" && box.top === 0 && box.left === 0 && box.right === 0 && box.bottom === 0) {
      return true;
    }
  }
  return false;
}

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const hasChildren = (element: ReactNode): element is ReactElement<{ children: ReactNode | ReactNode[] }> =>
  isValidElement<{ children?: ReactNode[] }>(element) && Boolean(element.props.children);
